import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import auth from "../feature/auth/auth.slice"
import product from "../feature/product/product.slice"
import category from "../feature/category/category.slice"
import cart from "../feature/cart/cart.slice"
import order from "../feature/order/order.slice"

export const store = configureStore({
    reducer: {
        auth,
        category,
        product,
        cart,
        order
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;