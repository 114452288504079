import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../../routes'
import { UserAuthContext } from '../../../../../context/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeContext } from '../../../../../context/ThemeContext'
import { convertPrice, formatPrice } from '../../../../utils/functions'
import { sumMaxPrice, sumMinPrice } from '../../auth/cart'
import { useAppDispatch, useAppSelector } from '../../../../../app/store'
import { reset, setOrder } from '../../../../../app/feature/order/order.slice'
import { ShoppingBagCuate } from '../../../../assets/illustration'
import { AlertModule } from '../../../../components/src/alert'
import { Textfield } from '../../../../components/src/field'
import { Button } from '../../../../components/src/button'

const SummaryContainer = ({items}: {items: any}) => {
  const {
    devise,
  } = useContext(ThemeContext);
  return (
    <div className='bt-order-summary__container'>
      <div className='bt-order-summary__box bt-order-summary-box'>
        <h2>Recapitulatif</h2>
        <div className='bt-order-summary-box__list'>
          <div className='bt-order-summary-box__item'>
            <span>Total Minimum (en {devise.toUpperCase()})</span>
            <strong>{formatPrice(convertPrice(sumMinPrice(items) , devise), devise)}</strong>
          </div>
          <div className='bt-order-summary-box__item'>
            <span>Total Maximum (en {devise.toUpperCase()})</span>
            <strong>{formatPrice(convertPrice(sumMaxPrice(items) , devise), devise)}</strong>
          </div>
          <div className='bt-order-summary-box__item'>
            <span>Total Livraison</span>
            <strong>ND</strong>
          </div>
        </div>
      </div>
      
      <div className='bt-order-summary__list'>
        {
          items.map((item: any, index: number) => (
            <div className={`bt-order-summary-product`} key={index}>
              <div className='bt-order-summary-product__image'>
                <img src={item.produit?.image || ""} alt="cart-item" />
              </div>
              <div className='bt-order-summary-product__container'>
                <strong>{item.produit?.label}</strong>
                <p>Prix minimun: <strong>{formatPrice(convertPrice(item.produit?.minPrice, devise), devise)}</strong> - Prix maximum: <strong>{formatPrice(convertPrice(item.produit?.maxPrice, devise), devise)}</strong></p>
                <p>Quantité: <strong>{item.quantity}</strong></p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const PaymentContainer = ({items, info}: {items: any[], info: any}) => {
  
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError ,message } = useAppSelector((state) => state.order);

  const [data, setData] = useState({
    products: items,
    customer: info.id,
    exceptedDeliveryDay: "",
    deliveryAddress: "",
    // agent: info.id
  })

  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.deliveryAddress){
      setError({
          field: "lastName",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    setError(null)
    dispatch(setOrder(data))
  }

  return (
    <div className='bt-order-info__container'>
      {
        isCreateSuccess ? (
          <div className='bt-order-info__success'>
            <ShoppingBagCuate/>
            <p>Votre commande a été enregistrée</p>
          </div>
        ) : (
          <>
            <h2>Informations pour la livraison</h2>
            {
              isCreateError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <form onSubmit={handleSubmit}>
              <div className='bt-settings-box__fields bt-settings-box-fields'>
                <div className='bt-settings-box__field'>
                  <Textfield
                      id={"exceptedDeliveryDay"}
                      value={data.exceptedDeliveryDay}
                      require={false}
                      changeValue={(val: string) => setData({...data, exceptedDeliveryDay: val})}
                      type={"date"}
                      label={"Peux etre livré sauf"}
                      error={error}
                      placeholder={"Veuillez insérer la date à laquelle vous serez potentiellement indisponible pour la livraison"}
                  />
                </div>
                <div className='bt-settings-box__field'>
                  <Textfield
                      id={"deliveryAddress"}
                      value={data.deliveryAddress}
                      require={true}
                      changeValue={(val: string) => setData({...data, deliveryAddress: val})}
                      type={"textarea"}
                      label={"Adresse de livraison"}
                      error={error}
                      placeholder={"Veuillez insérer votre adresse de livraison: Pays, Ville, Boite postal, Secteur - Rue/Avenue, Numero de porte..."}
                  />
                </div>
              </div>
              <div className='bt-settings-box__action'>
                <Button btnType={"submit"} label={isCreateLoading ? "Enregistrement en cours..." : "Commander"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={isCreateLoading} iconLeftClass={"icon-spin"}/>
              </div>
            </form>
          </>
        )
      }

    </div>
  )
}


const tabifyCart = (tabs: any[]) => {
  return tabs.map((item: any) => ({
    id: item.id,
    quantity: item.quantity
  }))
}


export const PaymentPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()
  const {state} = useLocation()

  useEffect(() => {
    if(state){
      if(state.length < 1){
        navigate(-1)
      }
    }else{
      navigate(-1)
    }
  }, [state, navigate])

  const dispatch = useAppDispatch();
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.commands}`)
    }
  }, [token, navigate]);
  
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <MainLayout>
      <div className='bt-page'>
        <div className='bt-order'>
          {
            info ? (
              <>
                <div className='bt-order-info'>
                  <PaymentContainer items={tabifyCart(state || [])} info={info} />
                </div>
                <div className='bt-order-summary'>
                  <SummaryContainer items={state} />
                </div>
              </>
            ) : null
          }
        </div>
      </div>
    </MainLayout>
  )
}
