import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProductCard } from '../../../../components/src/card/ProductCard';
import { AdsCard } from '../../../../components/src/card/AdsCard';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { SliderNextArrow, SliderPrevArrow } from '../../main';
import images from '../../../../assets/images';
import { links } from '../../../../../routes';
import { Button } from '../../../../components/src/button';
import { ModalLayout } from '../../../../components/src/modal';
import CopyToClipboard from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  // LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Facebook, Linkedin, Telegram, Twitter, Whatsapp } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { getProduct, getProducts, reset } from '../../../../../app/feature/product/product.slice';
import { convertPrice, formatPrice } from '../../../../utils/functions';
import { ThemeContext } from '../../../../../context/ThemeContext';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { getCarts, setCart } from '../../../../../app/feature/cart/cart.slice';
import { Toast } from '../../../../components/src/alert';
import ImageGallery from "react-image-gallery";


const ImagesVideosBanner = ({images, videos}:{images: any[], videos: any[]}) => {
  
  const [files, setFiles] = useState<any[]>([])

  useEffect(() => {
    if(videos){
      videos.forEach((el: any) => {
        setFiles((list) => [...list, {
          original: el,
          thumbnail: "https://placehold.co/600x400?text=Video",
          renderItem: () => (
            <video src={el} controls />
          ),
        }])
      })
    }
  }, [videos])
  useEffect(() => {
    if(images){
      images.forEach((el: any) => {
        setFiles((list) => [...list, {
          original: el,
          thumbnail: el,
        }])
      })
    }
  }, [images])
  
  return (
    <div className='bt-product-banner__images bt-product-banner-images'>
      <ImageGallery
        items={files}
        showPlayButton={false}
        showFullscreenButton={true}
        slideInterval={1000}
        slideOnThumbnailOver={true}
        showIndex={true}
        onPlay={() => {
          alert("slideshow is now playing!");
        }}
      />
    </div>
  )
}


const RecentProducts = ({products}:{products: any[]}) => {
  let setting2 = {
    dot: false,
    infinite: false,
    speed: 1000,
    slidesToShow: products?.length > 4 ? 4 : 1,
    slidesToScroll:  1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: products?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: products?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='bt-home-products'>
      <div className='bt-home-products__header'>
      <h2>De la même catégorie</h2>
      </div>
      <div className='bt-home-products__slider'>
        <Slider {...setting2}>
          {
            products.slice(0, 10).map((item, index) => (
              <div className='bt-home-products__slide' key={index}>
                <ProductCard item={item}/>
              </div>
            ))
          }
          <div className='bt-home-products__slide'>
            <AdsCard/>
          </div>
        </Slider>
      </div>
    </div>
  )
} 

const RecommandedProducts = ({products}:{products: any[]}) => {
  let setting2 = {
    dot: false,
    infinite: false,
    speed: 1000,
    slidesToShow: products?.length > 4 ? 4 : 1,
    slidesToScroll:  1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: products?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: products?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='bt-home-products'>
      <div className='bt-home-products__header'>
      <h2>De la même catégorie</h2>
      </div>
      <div className='bt-home-products__slider'>
        <Slider {...setting2}>
          {
            products.slice(0, 10).map((item, index) => (
              <div className='bt-home-products__slide' key={index}>
                <ProductCard item={item}/>
              </div>
            ))
          }
          <div className='bt-home-products__slide'>
            <AdsCard/>
          </div>
        </Slider>
      </div>
    </div>
  )
} 


export const ProductPage = () => {
  const location = useLocation();
  const {
    devise,
  } = useContext(ThemeContext);
  const { info } = useContext(UserAuthContext); 
  const searchParams = new URLSearchParams(location.search);

  const url = window.location.origin + location.pathname

  const tabs = searchParams.get('tabs')
  const {slug} =useParams()
  const [qte, setQte] = useState<number>(1)
  const [openShare, setOpenShare] = useState<boolean>(false)
  const [copyCode, setCopyCode] = useState<boolean>(false)

  const { image2} = images
  let navigate = useNavigate()
  const [isNewNotif, setIsNewNotif] = useState<boolean>(false)
  const [isNewCNotif, setIsNewCNotif] = useState<boolean>(false)

  const dispatch = useAppDispatch();
  const {isLoading, isError, products, product, message}: any = useAppSelector((state) => state.product);
  const {isCreateLoading, isCreateSuccess, isEditLoading, isEditError, isEditSuccess , isLoading: isCLoading, isError: isCError, isCreateError, carts, message: cMessage}: any = useAppSelector((state) => state.cart);
  
  useEffect(() => {
    if(slug){
      dispatch(getProduct(slug))
    }
    dispatch(getProducts({
      page: 0,
      size: 300,
    }))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, slug])

  useEffect(() => {
    if(info){
      dispatch(getCarts({
        page: 0,
        size: 500,
      }))
    }
  }, [info, dispatch])

  useEffect(() => {
    if(carts && product){
      if(carts.length > 0){
        if(carts.filter((el: any) => el.product?.id === product.id).length > 0){
          setQte(carts.filter((el: any) => el.product?.id === product.id)[0].quantity)
        }
      }
    }
  }, [carts, product])
  
  useEffect(() => {
    if(isEditError){
      setIsNewNotif(true)
    }
  }, [isEditError])
  
  useEffect(() => {
    if(isEditSuccess){
      setIsNewNotif(true)
    }
  }, [isEditSuccess])

  useEffect(() => {
    if(isCreateSuccess){
      setIsNewCNotif(true)
    }
  }, [isCreateSuccess])
  
  useEffect(() => {
    if(isCreateError){
      setIsNewCNotif(true)
    }
  }, [isCreateError])

  if(isLoading || isCLoading){
    return (
      <div className={"bt-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError){
    return (
      <div className={"kbn-user-error"}>
          <div className={"kbn-user-error__container"}>
            <i className="icon-triangle-exclamation"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kbn-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }

  if(info && isCError){
    return (
      <div className={"kbn-user-error"}>
          <div className={"kbn-user-error__container"}>
            <i className="icon-triangle-exclamation"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{cMessage}</p>
            <div className={"kbn-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }

  return (
    <MainLayout title="Aperçu d'un article">
      <div className='bt-page'>
        <div className='bt-product-banner'>
          <ImagesVideosBanner images={product?.images} videos={product?.videos}/>
          <div className='bt-product-banner__side'>
            <div className='bt-product-banner__info bt-product-banner-info'>
              <div className='bt-product-banner-info__details'>
                <h2>
                {product?.label}</h2>
                <p>Categorie: <Link to={links.product_search + `?wordkeys=${product?.category?.parent?.id}&type=category`}>{product?.category?.parent?.label}</Link> - <Link to={links.product_search + `?wordkeys=${product?.category?.id}&type=subcategory`}>{product?.category?.label}</Link></p>
                <div className='bt-product-banner-info__avis bt-product-banner-info-avis'>
                  <div className='bt-product-banner-info-avis__stars'>
                    <div className='bt-product-banner-info-avis__star is--active'></div>
                    <div className='bt-product-banner-info-avis__star is--active'></div>
                    <div className='bt-product-banner-info-avis__star is--active'></div>
                    <div className='bt-product-banner-info-avis__star is--active'></div>
                    <div className='bt-product-banner-info-avis__star'></div>
                  </div>
                  <span>120k avis</span>
                </div>
              </div>
              <div className='bt-product-banner-info__orders'>
                <div className='bt-product-banner-info__price'>
                  <span>A partir de</span>
                  <strong>{formatPrice(convertPrice(product?.minPrice || 0, devise), devise)}</strong>
                  <p>Prix maximum: {formatPrice(convertPrice(product?.maxPrice || 0, devise), devise)}</p>
                </div>
                {
                  (info) ? (
                    <>
                    <div className='bt-product-banner-info__qte'>
                      <span>Quantité:</span>
                      <button type='button' className={`${qte <= 1 ? "is--disable" : ""}`} onClick={() => {
                        if(qte > 1){
                          setQte(qte - 1)
                        }
                      }}>
                        <i className="icon-minus"></i>
                      </button>
                      <input type="number" min={1} value={qte} onChange={(e) => setQte(parseInt(e.target.value))}/>
                      <button type='button' className={``} onClick={() => {
                        setQte(qte + 1)
                      }}>
                        <i className="icon-plus"></i>
                      </button>
                    </div>
                    <div className='bt-product-banner-info__actions'>
                      <Button btnType={'button'} label={(isCreateLoading || isEditLoading) ? "Ajout en cours..." : 'Ajouter au panier'} btnStyle={'is--secondary'} active={!isCreateLoading || !isEditLoading} withIconLeft={true} iconLeftClass={(isCreateLoading || isEditLoading) ? "icon-spin" : 'icon-cart-plus'} onClick={() => {
                        if(info){
                          if(qte > 0){
                              if(carts?.filter((el: any) => el.product?.id === product?.id)?.length > 0){
                                let data = {
                                  productId: product?.id,
                                  quantity: qte
                                }
                                dispatch(setCart(data))
                              }else{
                                let data = {
                                  productId: product?.id,
                                  quantity: qte
                                }
                                dispatch(setCart(data))
                              }
                          }
                        }else{
                          navigate(links.login+ `?url=${links.product + product?.id}`)
                        }
                      } }/>
                      {/* <Button btnType={'button'} label={'Passer la commande'} btnStyle={'is--primary'} active={true} withIconLeft={true} iconLeftClass='icon-credit-card' onClick={() =>  navigate(links.product_payment+`?product=${slug}&qte=${qte}&step=summary`)}/> */}
                    </div>
                    </>
                  ) : null
                }
                    
              </div>
              <button type='button' className='bt-product-banner-info__share' onClick={() => setOpenShare(true)}>
                <i className="icon-arrow-up-right-from-square"></i>
              </button>
            </div>
            <a href={"google.com"} className='bt-product-banner__ads' target='_blank' rel='noreferrer'>
              <img src={image2} alt="ads" />
              <span>Ads</span>
            </a>
          </div>
        </div>

        <RecentProducts products={products.filter((el: any) => el.category?.id === product?.category?.id)}/>
        
        <div className='bt-product-navigation'>
          <div className='bt-product-navigation__nav bt-product-navigation-nav'>
            <button type='button' className={`bt-product-navigation-nav__item ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.product + slug + `?tabs=presentation`)}>
              <span>Presentation</span>
            </button>
            <button type='button' className={`bt-product-navigation-nav__item ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`} onClick={() => navigate(links.product + slug + `?tabs=comments`)}>
              <span>Retours clients (120k)</span>
            </button>
          </div>
          <div className={`bt-product-navigation__container ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`}>
            <div dangerouslySetInnerHTML={{ __html: product?.description }}/>
          </div>
          <div className={`bt-product-navigation__container ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`}></div>
        </div>

        <RecommandedProducts products={products}/>

        {isEditError && isNewNotif && (
          <Toast id={"notification-edit"} status={'st-danger'} fnc={setIsNewNotif}>
            <span>{message}</span>
          </Toast>
        )}
        {isEditSuccess && isNewNotif && (
          <Toast id={"notification-edit"} status={'st-success'} fnc={setIsNewNotif}>
            <span>{"La quantité a été modifiée"}</span>
          </Toast>
        )}
        {isCreateError && isNewCNotif && (
          <Toast id={"notification-add"} status={'st-danger'} fnc={setIsNewCNotif}>
            <span>{message}</span>
          </Toast>
        )}
        {isCreateSuccess && isNewCNotif && (
          <Toast id={"notification-add"} status={'st-success'} fnc={setIsNewCNotif}>
            <span>{"L'article a été ajouté à votre panier"}</span>
          </Toast>
        )}

        <ModalLayout size={'is--md'} id={'share'} fnc={(val: boolean) => setOpenShare(val)} isOpen={openShare} withClose={true}>
          <div className='bt-modal-share'>
            <div className='bt-modal-share__header'>
              <h2>Partager sur les réseaux</h2>
            </div>
            <div className='bt-modal-share__copy bt-modal-share-copy'>
              <div className='bt-modal-share-copy__container'>
                <span>{url}</span>
              </div>
              <CopyToClipboard text={url} onCopy={() => setCopyCode(true)}>
                <button className={`bt-modal-share-copy__btn ${copyCode ? "is--active" : ""}`} type='button'>
                  <i className={copyCode ? "icon-party-bell" : "icon-link"}></i>
                </button>
              </CopyToClipboard>
            </div>
            <span>Ou</span>
            <div className='bt-modal-share__socials bt-modal-share-socials'>
              <FacebookShareButton url={url}>
                <div className='bt-modal-share-social facebook'>
                  <Facebook/>
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={url}>
                <div className='bt-modal-share-social twitter'>
                  <Twitter/>
                </div>
              </TwitterShareButton>
              <LinkedinShareButton url={url}>
                <div className='bt-modal-share-social linkedin'>
                  <Linkedin/>
                </div>
              </LinkedinShareButton>
              <WhatsappShareButton url={url}>
                <div className='bt-modal-share-social whatsapp'>
                  <Whatsapp/>
                </div>
              </WhatsappShareButton>
              <TelegramShareButton url={url}>
                <div className='bt-modal-share-social telegram'>
                  <Telegram/>
                </div>
              </TelegramShareButton>
              <EmailShareButton url={url}>
                <div className='bt-modal-share-social email'>
                  <i className="icon-mail-open"></i>
                </div>
              </EmailShareButton>
            </div>
          </div>
        </ModalLayout>
      </div>
    </MainLayout>
  )
}
