import React from 'react'
import { MainLayout } from '../../../../layout'
import { LogoIcon, Service1Illustr, Service2Illustr, Service3Illustr } from '../../../../assets/illustration'
import images from '../../../../assets/images'

export const AboutPage = () => {
  const {image1, image2} = images
  return (
    <MainLayout here='about' title='A propos de nous'>
      <div className='bt-about-page'>
        <div className='bt-banner'>
          <div className='bt-banner__image'>
            <img src={image1} alt="banner" />
          </div>
          <div className='bt-banner__container'>
            <LogoIcon/>
            <strong>A propos de nous</strong>
            <h1>Avec BERONG TRADE TOUT EST À PORTÉE DE MAIN DÉSORMAIS.</h1>
          </div>
        </div>
        <div className='bt-about'>
          <div className='bt-about__container'>
            <strong>Qui sommes-nous?</strong>
            <p>Après une expérience cumulée de près de 20 ans dans le contexte d affaire en Chine , doublée d’une connaissance parfaite de l’immobilier nous mettons en place BERONG TRADE pour résoudre un problème crucial. Acheter à l’étranger en toute sécurité tout en restant chez soi. <br />
              Les problèmes cruciaux auquels les opérateurs sont confrontés de nos  jours sont de plusieurs ordre dans un contexte généralisé de vente en ligne. Ces difficultés sont les suivantes : le coût, le transfert de fonds le contrôle  qualité et la logistique. Notre structure règle efficacement toutes ses difficultés en un clic. 
            </p>
          </div>
          <div className='bt-about__images'>
            <div className='bt-about__image'>
              <img src={image2} alt="about" />
            </div>
            <div className='bt-about__box'>
              <span>Près de</span>
              <h1>20</h1>
              <p>ans d'experience</p>
            </div>
          </div>
        </div>
        <div className='bt-service'>
          <div className='bt-service__header'>
            <h1>Nos services</h1>
            <span>Que faisons-nous?</span>
          </div>
          <div className='bt-service__grid'>
            <div className='bt-service__item bt-service-item'>
                <div className='bt-service-item__illustration'>
                  <Service1Illustr/>
                </div>
                <div className='bt-service-item__text'>
                  <strong>Shipping</strong>
                </div>
            </div>
            <div className='bt-service__item bt-service-item'>
                <div className='bt-service-item__illustration'>
                <Service2Illustr/>
                </div>
                <div className='bt-service-item__text'>
                  <strong>Sourcing</strong>
                </div>
            </div>
            <div className='bt-service__item bt-service-item'>
                <div className='bt-service-item__illustration'>
                <Service3Illustr/>
                </div>
                <div className='bt-service-item__text'>
                  <strong>Contrôle Qualité</strong>
                </div>
            </div>
          </div>
        </div>
        <div className='bt-promote'>
          <div className='bt-promote__image'>
            <img src={image1} alt="promote" />
          </div>
          <div className='bt-promote__text'>
            <p>Nous contrôlons rigoureusement vos achats : la qualité , la quantité , le transport en vous permettant de payer selon le moyen qui vous convienne ou que vous soyez. 
            </p>
          </div>
        </div>
        <div className='bt-promote'>
          <div className='bt-promote__text'>
            <p>
              Notre plate forme spécialisée dans le commerce en ligne offre un nombre important de choix , nous sommes réactifs 24/24 et nous vous livrons à l’endroit de votre choix. BERONG TRADE vous donne l occasion de suivre le statut de vos commandes au quotidien. 
            </p>
          </div>
          <div className='bt-promote__image'>
            <img src={image2} alt="promote" />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
