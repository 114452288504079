import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../utils';
import { OrderState } from '../../interface/GeneralStateInterface';


const URI = APIURL+"/order"

const initialState: OrderState = {
    orders: [],
    order: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
    orders_info: null
}

export const setOrder = createAsyncThunk<any, Object>(
    'order/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getOrders = createAsyncThunk<any, Object>(
    'order/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/list?${content?.createdBy ? `agent=${content?.createdBy}&` : ""}${content?.agent ? `agent=${content?.agent}&` : ""}${content?.status ? `status=${content?.status}&` : ""}${content?.customer ? `customer=${content?.customer}&` : ""}pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getOrder = createAsyncThunk<any, Object>(
    'order/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateOrder = createAsyncThunk<any, Object>(
    'order/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const activeOrder = createAsyncThunk<any, Object>(
    'order/active',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/status?id=${content?.id}`,
                token: tokens,
                content: {}
            }
            const reponse = await updateProtecteItems(data)

            return {...content, reponse}

        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false


            state.isDeleteError = false
            state.isDeleteLoading = false
            state.isDeleteSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isImportLoading = false
            state.isImportSuccess = false
            state.isImportError = false

            state.isFileLoading = false
            state.isFileError = false
            state.isFileSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getOrders.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getOrders.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.orders_info = action.payload
            state.orders = action.payload?.content
        })
        .addCase(getOrders.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getOrder.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getOrder.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.order = action.payload
        })
        .addCase(getOrder.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.order = null
        })

        .addCase(updateOrder.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateOrder.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            // state.product = action.payload
        })
        .addCase(updateOrder.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(activeOrder.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(activeOrder.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.order = {...state.order, status: action.payload.status}
        })
        .addCase(activeOrder.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(setOrder.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(setOrder.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.orders = [action.payload, ...state.orders]
            state.order = action.payload
        })
        .addCase(setOrder.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.order = null
        })

    },
})

export const { reset } = orderSlice.actions
export default orderSlice.reducer